@charset 'UTF-8';
/* Slider */
.slick-loading .slick-list
{
    background: #fff url(/_next/static/media/ajax-loader.0b80f665.gif) center center no-repeat;
}

/* Icons */
@font-face
{
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url(/_next/static/media/slick.25572f22.eot);
    src: url(/_next/static/media/slick.25572f22.eot?#iefix) format('embedded-opentype'), url(/_next/static/media/slick.653a4cbb.woff) format('woff'), url(/_next/static/media/slick.6aa1ee46.ttf) format('truetype'), url(/_next/static/media/slick.f895cfdf.svg#slick) format('svg');
}
/* Arrows */
.slick-prev,
.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus
{
    color: transparent;
    outline: none;
    background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before
{
    opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before
{
    opacity: .25;
}

.slick-prev:before,
.slick-next:before
{
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: .75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.slick-prev
{
    left: -25px;
}
[dir='rtl'] .slick-prev
{
    right: -25px;
    left: auto;
}
.slick-prev:before
{
    content: '←';
}
[dir='rtl'] .slick-prev:before
{
    content: '→';
}

.slick-next
{
    right: -25px;
}
[dir='rtl'] .slick-next
{
    right: auto;
    left: -25px;
}
.slick-next:before
{
    content: '→';
}
[dir='rtl'] .slick-next:before
{
    content: '←';
}

/* Dots */
.slick-dotted.slick-slider
{
    margin-bottom: 30px;
}

.slick-dots
{
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
}
.slick-dots li
{
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
}
.slick-dots li button
{
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
}
.slick-dots li button:hover,
.slick-dots li button:focus
{
    outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before
{
    opacity: 1;
}
.slick-dots li button:before
{
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: .25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.slick-dots li.slick-active button:before
{
    opacity: .75;
    color: black;
}

/* Slider */
.slick-slider
{
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

.product-slider .slick-slide {
  margin: 0 6px;
  max-width: calc(100vw - 40px);
}

.product-slider .slick-list {
  padding: 2px 26px !important;
  width: 100%;
  margin: 0 -6px;
}

.product-slider.initial .slick-track {
  display: flex !important;
  width: 100% !important;
  justify-content: flex-start;
}

.product-slider .slick-track::before {
  display: none;
}

.product-slider .slick-track::after {
  display: none;
}

@media screen and (min-width: 1024px) {
  .product-slider .slick-slide {
    margin: 0 10px;
  }
}

@media screen and (min-width: 1280px) {
  .product-slider .slick-list {
    padding-inline: 46px !important;
  }
}

.reviews-carousel .slick-list {
  margin: 0 -10px;
}
.reviews-carousel .slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: stretch;
}

.reviews-carousel .slick-slide > div {
  padding: 0 10px;
  width: 100%;
}

.slide-list .slick-slide > div {
  display: flex;
  justify-content: center;
  padding: 0 8px;
}

.tag-slider .slick-slide {
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.tag-slider .slick-track {
  display: flex !important;
  width: 100% !important;
  justify-content: flex-start;
  margin: 0 1.25rem;
  gap: 0.5rem;
}

.tag-slider .slick-track::before {
  display: none;
}

.tag-slider .slick-track::after {
  display: none;
}

.teaser-slider .slick-list {
  margin: 0 -6px;
}

.teaser-slider .slick-slide > div {
  padding: 0 6px;
}

.testimonial-slider .slick-slide {
  margin: 0 6px;
  max-width: calc(100vw - 40px);
}

.testimonial-slider.initial .slick-track {
  display: flex !important;
  width: 100% !important;
  justify-content: flex-start;
}

.testimonial-slider .slick-track::before {
  display: none;
}

.testimonial-slider .slick-track::after {
  display: none;
}

@font-face {
font-family: '__materialSymbols_59deff';
src: url(/_next/static/media/74d7de20d24523b3-s.woff2) format('woff2');
font-display: block;
font-weight: 100 700;
font-style: normal;
}@font-face {font-family: '__materialSymbols_Fallback_59deff';src: local("Arial");ascent-override: 50.19%;descent-override: 4.56%;line-gap-override: 0.00%;size-adjust: 219.15%
}.__className_59deff {font-family: '__materialSymbols_59deff', '__materialSymbols_Fallback_59deff';font-style: normal
}.__variable_59deff {--font-family-symbols: '__materialSymbols_59deff', '__materialSymbols_Fallback_59deff'
}

